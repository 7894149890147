import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAuthenticatedRequest } from "../hooks/AuthenticatedApiCallHook";
import KeyboardArrowLeft  from '@mui/icons-material/KeyboardArrowLeft';
import { UserDetails } from "../types";
import { createUser } from "../api/user";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AddUser() {
  const [isEdit, _setIsEdit] = React.useState(false);
  const [userData, setUserData] = React.useState<UserDetails>({
    full_name: "",
    username: "",
    password: "",
    role: "la",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authenticatedApiHook = useAuthenticatedRequest();

  const handleChange = (name: string, value: any) => {
    if (name === "username") {
      if (!/^[a-zA-Z0-9]*$/.test(value)) {
        setErrorMessage(t("Only alphabets and numbers are allowed for username"));
      } else {
        // Store the username in lowercase
        setUserData({ ...userData, [name]: value.toLowerCase() });
        setErrorMessage(""); 
      }
    }
    else if (name === "password" && (/\s/.test(value) || /[\uD83C-\uDBFF\uDC00-\uDFFF]/.test(value))) {
      setErrorMessage("No spaces are allowed in the password");
    } 
    else {
      setErrorMessage(""); 
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    const newUser = await createUser(authenticatedApiHook,userData);
    if (newUser) {
      alert("User created successfully");
      navigate("/landing");
    }
  };

  return (
    <Container disableGutters sx={{height: '100vh', overflowY: 'scroll', pb: 8}}>
      {/* Header Box */}
      <Box display={'flex'} flexDirection={'row'} p={'16px'} bgcolor={'white'} width={'100vw'} maxWidth={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <IconButton onClick={() => navigate('/associates')} style={{padding: 0}}>
            <KeyboardArrowLeft />
        </IconButton>
        <Typography fontSize={'16px'} fontWeight={500}>
          {t('Add Associate Librarian')}
        </Typography>
        <Box width={'30px'}></Box>
      </Box>

      <Typography textAlign={"left"} fontSize={'14px'} fontWeight={400} p={'16px'}>
        {t('Please enter all the required(*) information')}
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap" }} px={'16px'} mt={2}>
        <Typography fontWeight={600}>{t('User Details')}</Typography>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t('Name of Associate Librarian')}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={userData.full_name}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="full_name"
            label={t('Name of Associate Librarian')}
            disabled={isEdit}
          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t('Username')}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            inputProps={{
              autoCapitalize: 'none',
            }}
            sx={{ background: "white", borderRadius: "12px" }}
            value={userData.username}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="username"
            label={t('Username')}
            disabled={isEdit}
          />
        </FormControl>
        <FormControl sx={{ my: 1, mt: 2 }} variant="outlined" fullWidth>
          <InputLabel
            sx={{ fontWeight: 400, color: "#949494" }}
            htmlFor="outlined-adornment-password"
          >
            {t('Password')}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            sx={{ background: "white", borderRadius: "12px" }}
            value={userData.password}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="password"
            label={t('Password')}
            disabled={isEdit}
          />
        </FormControl>

        <Typography variant="h5" component="h2" fontSize={16} fontWeight={300} mx={0.4} textAlign={"left"} color="error">
          {errorMessage}
        </Typography>
      </Box>

      <Box margin={1.5}>
        <Button disabled={userData.password == '' || userData.username == '' || userData.full_name == ''} variant="contained" onClick={handleSubmit} sx={{textTransform: "none", py: 2, borderRadius: 150, width: '100%'}}>
          <Typography fontSize={16} fontWeight={400} color="white" letterSpacing={"0.96px"}>
            {t("Save")}
          </Typography>
        </Button>
      </Box>
      
    </Container>
  );
}
