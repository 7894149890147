import { useEffect, useState } from 'react';
import { useQuery,useQueryClient } from '@tanstack/react-query';
import { getLibraryInstances } from '../api/items';
import { Instance } from '../types';
import { getLibraryBookCount } from '../api/library';
import { useAuthenticatedRequest } from '../hooks/AuthenticatedApiCallHook';

export const useItems = (search: string, offset: number, itemType?: string) => {
  const [items, setItems] = useState<Instance[]>([]);
  const [totalBookCount, setTotalBookCount] = useState<number>(0);
  const [totalInstanceCount, setTotalInstanceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const authenticatedApiHook = useAuthenticatedRequest();
  const queryClient = useQueryClient();
  const {
    data: response,
    isLoading,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['fetchInstances', search, offset, itemType],
    queryFn: () =>
      getLibraryInstances(
        authenticatedApiHook,
        search,
        offset,
        false,
        'recent',
        undefined,
        false,
        itemType,
      ),
    staleTime: 60 * 1000,
  });

  // Query to fetch library book count
  const {
    data: bookCountDetails,
    isLoading: bookCountLoading,
    isFetching: bookCountFetching,
    error: bookCountError,
  } = useQuery({
    queryKey: ['fetchBookCount', search, itemType],
    queryFn: () => getLibraryBookCount(authenticatedApiHook,itemType, search,false),
    staleTime: 60 * 1000,
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['fetchInstances'] });
    queryClient.invalidateQueries({ queryKey: ['fetchBookCount'] });
  }, [search, queryClient]);

  useEffect(() => {
    if (response) {
      setLoading(true);
      if (search && offset==0) {
        setItems(response.instances);
      } else {
        setItems((prevItems) => [
          ...prevItems,
          ...response.instances,
        ]);
      }
      setTotalInstanceCount(response.count || 0);
    }
  }, [response, search, offset]);

  useEffect(() => {
    if (bookCountDetails) {
      setTotalBookCount(bookCountDetails.count || 0);
    }
  }, [bookCountDetails]);

  useEffect(() => {
    setLoading(bookCountFetching || isFetching);
  }, [isFetching, bookCountFetching]);

  if (error || bookCountError) {
    console.error('Error loading items:', error || bookCountError);
  }

  return { items, totalBookCount, loading, totalInstanceCount };
};
